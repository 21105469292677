<template>
  <div>
    <MobileMenu v-if="emailVerified" />
    <div class="flex">
      <!-- BEGIN: Side Menu -->
      <nav class="side-nav" v-if="emailVerified && colaborador">
        <!-- BEGIN: Logo -->
        <router-link
          :to="{ name: 'colab.dashboard' }"
          tag="a"
          class="intro-x flex items-center pl-5 pt-4"
        >
          <img
            alt="Vibrart 2023"
            class="w-full mx-auto"
            src="@/assets/images/vibrart-logo.png"
          />
        </router-link>
        <!-- END: Logo -->
        <div class="side-nav__devider my-6"></div>
        <ul>
          <!-- BEGIN: First Child -->
          <template v-for="(menu, menuKey) in formattedMenu">
            <li
              v-if="menu === 'devider'"
              :key="menu + menuKey"
              class="side-nav__devider my-6"
            ></li>
            <li v-else :key="menu + menuKey">
              <SideMenuTooltip
                tag="a"
                :content="menu.title"
                :href="
                  menu.subMenu
                    ? 'javascript:;'
                    : router.resolve({ name: menu.pageName }).path
                "
                class="side-menu"
                :class="{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown
                }"
                @click="linkTo(menu, router, $event)"
              >
                <div class="side-menu__icon">
                  <component :is="menu.icon" />
                </div>
                <div class="side-menu__title">
                  {{ menu.title }}
                  <div
                    v-if="menu.subMenu"
                    class="side-menu__sub-icon"
                    :class="{ 'transform rotate-180': menu.activeDropdown }"
                  >
                    <ChevronDownIcon />
                  </div>
                </div>
              </SideMenuTooltip>
              <!-- BEGIN: Second Child -->
              <transition @enter="enter" @leave="leave">
                <ul v-if="menu.subMenu && menu.activeDropdown">
                  <li
                    v-for="(subMenu, subMenuKey) in menu.subMenu"
                    :key="subMenuKey"
                  >
                    <SideMenuTooltip
                      tag="a"
                      :content="subMenu.title"
                      :href="
                        subMenu.subMenu
                          ? 'javascript:;'
                          : router.resolve({ name: subMenu.pageName }).path
                      "
                      class="side-menu"
                      :class="{ 'side-menu--active': subMenu.active }"
                      @click="linkTo(subMenu, router, $event)"
                    >
                      <div class="side-menu__icon">
                        <ActivityIcon />
                      </div>
                      <div class="side-menu__title">
                        {{ subMenu.title }}
                        <div
                          v-if="subMenu.subMenu"
                          class="side-menu__sub-icon"
                          :class="{
                            'transform rotate-180': subMenu.activeDropdown
                          }"
                        >
                          <ChevronDownIcon />
                        </div>
                      </div>
                    </SideMenuTooltip>
                    <!-- BEGIN: Third Child -->
                    <transition @enter="enter" @leave="leave">
                      <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                        <li
                          v-for="(lastSubMenu,
                          lastSubMenuKey) in subMenu.subMenu"
                          :key="lastSubMenuKey"
                        >
                          <SideMenuTooltip
                            tag="a"
                            :content="lastSubMenu.title"
                            :href="
                              lastSubMenu.subMenu
                                ? 'javascript:;'
                                : router.resolve({ name: lastSubMenu.pageName })
                                    .path
                            "
                            class="side-menu"
                            :class="{ 'side-menu--active': lastSubMenu.active }"
                            @click="linkTo(lastSubMenu, router, $event)"
                          >
                            <div class="side-menu__icon">
                              <ZapIcon />
                            </div>
                            <div class="side-menu__title">
                              {{ lastSubMenu.title }}
                            </div>
                          </SideMenuTooltip>
                        </li>
                      </ul>
                    </transition>
                    <!-- END: Third Child -->
                  </li>
                </ul>
              </transition>
              <!-- END: Second Child -->
            </li>
          </template>
          <!-- END: First Child -->
        </ul>
      </nav>
      <!-- END: Side Menu -->
      <!-- BEGIN: Content -->
      <div :class="emailVerified && colaborador ? 'content' : 'flex content-center w-full'">
        <TopBar v-if="emailVerified && colaborador" />
        <router-view v-if="emailVerified && colaborador" />
        <Patrocinadores v-if="emailVerified && colaborador" />

        <div class="flex justify-center items-center w-full mx-auto my-auto" v-if="!emailVerified" style="transform: translateY(40%)">
          <div class="text-center" v-if="!emailSent">
            <h1 class="text-xl" v-if="!emailSent">
              Para continuar, necesitas verificar tu cuenta con el correo que te fue enviado.
            </h1>
            <button class="btn btn-primary w-48 mr-1 mb-2 mt-8" @click="handleSendingEmailVerification" v-if="!sendingEmail && !emailSent">
              Enviar de nuevo...
            </button>
            <p v-if="sendingEmail && !emailSent">Enviando...</p>
            <button class="btn btn-secondary w-48 mr-1 mb-2 mt-8" @click="updatePage">
              Actualizar
            </button>
          </div>
          <div v-if="emailSent" class="text-center">
            <h1 class="text-xl">
              Te hemos enviado un correo de verificación.
              Por favor haz click en el link para verificar tu cuenta.
            </h1>

            <button class="btn btn-secondary w-48 mr-1 mb-2 mt-8" @click="updatePage">
              Actualizar
            </button>
          </div>
        </div>
      </div>
      <!-- END: Content -->
    </div>
  </div>
</template>

<script>
// Libraries / Utilities
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { helper as $h } from '@/utils/helper'
import { fbAuth } from '@/plugins/firebase'
import { sendEmailVerification } from 'firebase/auth'

// Components
import TopBar from '@/layouts/colaboradores/components/top-bar/Main.vue'
import MobileMenu from '@/layouts/colaboradores/components/mobile-menu/Main.vue'
import SideMenuTooltip from '@/layouts/colaboradores/components/side-menu-tooltip/Main.vue'
import { linkTo, nestedMenu, enter, leave } from '../index'
import Patrocinadores from '@/components/patrocinadores/Patrocinadores'

export default defineComponent({
  components: {
    Patrocinadores,
    TopBar,
    MobileMenu,
    SideMenuTooltip
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const formattedMenu = ref([])
    const sideMenu = computed(() =>
      nestedMenu(store.state.colaboradores.menu, route)
    )
    const colaborador = computed(() => store.state.colaboradores.auth.colaborador)
    const sendingEmail = ref(false)
    const emailSent = ref(false)
    let domain = null

    if (window.location.hostname === 'vibrart-2022.web.app' ||
      window.location.hostname === 'vibrart-2022.firebaseapp.com') {
      domain = 'https://vibrart-2022--dev-huo9q2kv.web.app/colaboradores'
    } else if (window.location.hostname === 'vibrart-2022--dev-huo9q2kv.web.app') {
      domain = 'https://vibrart-2022--dev-huo9q2kv.web.app/colaboradores'
    } else {
      domain = window.location.protocol + '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '') + '/colaboradores'
    }

    const handleSendingEmailVerification = () => {
      sendingEmail.value = true

      sendEmailVerification(fbAuth.currentUser, { url: domain })
        .then(() => {
          emailSent.value = true
          sendingEmail.value = false
        })
    }

    const updatePage = () => {
      window.location.reload()
    }

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value)
      }
    )

    onMounted(() => {
      cash('body')
        .removeClass('error-page')
        .removeClass('login')
        .addClass('main')
      formattedMenu.value = $h.toRaw(sideMenu.value)
    })

    return {
      updatePage,
      sendingEmail,
      colaborador,
      emailSent,
      emailVerified: true,
      handleSendingEmailVerification,
      formattedMenu,
      router,
      linkTo,
      enter,
      leave
    }
  }
})
</script>

<style>
.app-version {
  color: rgba(0, 0, 0, 0.5);
}
</style>
